import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { paymentSource } from "src/utils/constants";
import { Plan } from "./plans";

export type OfferTrial = {
  trial_price: number;
  trial_duration: number;
};

export type Offer = {
  addon_product_id: string;
  title: string;
  description: string;
  image_name: string;
  price: number;
  regular_price: number;
  per_month: boolean;
  shipping_address_required: boolean;
  is_free_shipping: boolean;
  is_free: boolean;
  is_sold_out: boolean;
  discount: number;
  label: string;
  is_hidden?: boolean;
  descriptor_product_id?: string;
  type: "transaction" | "bundle" | "subscription";
  trial_items?: OfferTrial[];
};

export enum courseConstants {
  healthyWeight = "HEALTHY_WEIGHT",
}

export enum reasonConstants {
  cancel = "cancel",
  revert = "revert",
  renewal = "renewal",
}

export enum AddonSubscriptionStatuses {
  COUNTEROFFER = "ADDON_COUNTEROFFER",
  TRIAL_EXTENSION = "ADDON_TRIAL_EXTENSION",
}

export type InstallmentBillingState = {
  renewal_date: string;
  can_cancel: boolean;
  canceled: boolean;
  eligible_for_counter_offer: boolean;
  status: string;
  contract_duration_in_months: number;
  payment_count: number;
  installment_price: number;
  total_price: number;
  sales_tax_amount: number;
  total_sales_tax_amount: number;
  is_biweekly: boolean;
};

export interface ServerContextState {
  initiatingContextType?: string;

  // Basic serverContext stubbing containing most of the fields we can expect
  // from serverContext.
  // Meta information
  currency_symbol?: string;

  // User Information
  user_id: string;
  email?: string;
  gdpr_consent?: string; // JSON in string

  // Feature flags
  trial_fees?: number[];

  // Plan information
  fast_forward_trial_plan?: Plan;
  purchased_fast_forward_trial?: boolean;
  claimed_by_email?: string;

  // Counter Offer
  // https://github.com/noom/growth/blob/develop/growth_api/context/counter_offer.py#L124
  // TODO: Can we provide better scoping based on the context that is active?
  // "result": "success",
  foreign_subscription_id?: string;
  plan_duration?: number;
  access_code?: string;
  subscription_id?: string;
  subscription_next_charge_amount?: number;
  current_plan_price?: number;
  current_plan_id?: string;
  current_plan_sales_tax_amount?: number;
  current_plan_sales_tax_rate?: number;
  // https://github.com/noom/growth/blob/develop/visitor/constants.py#L23
  subscription_status?:
    | "ACTIVE"
    | "PAST_DUE"
    | "PENDING"
    | "INACTIVE"
    | "FRAUDULENT";
  subscription_statuses?: string[];
  addon_subscription_statuses?: AddonSubscriptionStatuses[];
  counteroffer_plan?: Plan;
  has_pending_subscription?: boolean;
  has_pending_bms_migration?: boolean; // used for program subscription counteroffer context
  bms_migration_old_subscription_id?: string; // used for addon counteroffer context. old_subscription_id for BMS Migration plan switch
  currency?: Plan["currency"];
  offered_discounted_plan?: Plan;
  eligible_for_percent_discount_offer?: boolean;
  discount_offer_percentage?: number;
  is_in_trial?: boolean;
  trial_duration?: number;
  expiry_time?: string;
  has_accepted_counter_offer?: boolean;
  subscription_is_hm?: boolean;
  has_expiring_subscription?: boolean;
  upid?: string;
  eligible_for_counter_offer?: boolean;
  is_tax_inclusive?: boolean;
  discounted_plan_sales_tax_rate?: number;
  discounted_plan_sales_tax_amount?: number;
  // NOTE(sumin)?: Hackily add subscriptions until the frontend doesn't need it anymore.
  subscriptions?: string; // JSON encoded object...

  addon_subscriptions?: any[];

  podcast_options?: Record<string, string>;

  experiment_tag?: string;

  // Addons
  offers?: Record<
    /** channel */ string,
    Record</** placement type */ string, Offer[]>
  >;

  total_price?: string;
  trial_fee?: string;
  sales_tax_amount?: string;
  minimal_discount_fast_forward_trial_plan?: Plan;
  minimal_discount_noom_plan_id?: string;
  fast_forward_trial_original_plan?: Plan;

  has_offers?: boolean;

  payment_source?: paymentSource;
  reason?: reasonConstants;
  course?: courseConstants;
  result?: string;

  is_auto_renewal_enabled?: boolean;
  next_charge_amount?: number;
  billing_cycle_interval?: string;
  billing_cycle_interval_count?: number;
  billing_state?: string;
  billing_country?: string;
  purchased_course_pack?: boolean;
  existing_user?: boolean;
  installment_billing?: InstallmentBillingState;

  days_since_program_start?: number;
  days_since_last_weigh_in?: number;
  purchased_summit?: boolean;
  has_premium_coaching?: boolean;
  has_premium_plus?: boolean;
  is_nx1_user?: boolean;
  active_program_is_healthy_weight?: boolean;
  has_med_subscription?: boolean;
  charge_med_video_visit_fee_on_cancel?: boolean;
  completed_med_video_visit_date_in_trial?: string;

  program_locale?: string;
  noom_plan_id?: string;

  // User for the new noom premium system to show a comparison against the initial purchased plan
  initial_plan_total_amount?: string;
  initial_plan_price?: string;
  initial_plan_discount_amount?: string;
  initial_plan_sales_tax?: string;
}

/**
 * Add properties that are intentionally omitted from the serverContext
 * during normal runtime.
 *
 * This should only be used during initialization of the redux store.
 */
export type ServerContextInit = ServerContextState & {
  noom_plan_id?: string;
  plan?: Plan;
};

const initialState: ServerContextState = {
  // Basic serverContext stubbing containing most of the fields we can expect
  // from serverContext.
  // Meta information
  currency_symbol: "$",

  // User Information
  user_id: "",
  email: "",
  gdpr_consent: "",

  // Feature flags
  trial_fees: [],
};

const serverContextSlice = createSlice({
  name: "serverContext",
  initialState,
  reducers: {
    // NOTE(patrick): ServerContext should only ever be updated as a whole from
    // the backend. Do not mutate serverContext!
    updateServerContext(state, action: PayloadAction<ServerContextState>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateServerContext } = serverContextSlice.actions;

export default serverContextSlice;
