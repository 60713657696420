/* eslint-disable no-underscore-dangle */
import React, { memo, useCallback, useEffect, useRef } from "react";
import type Chart from "chart.js/auto";
import { getWeightInformation } from "@utils/calculateGoals";
import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import drawPersonalizedPaceWeightGraph, {
  GraphCustomization,
} from "src/graphs/weightGraph/personalizedPaceWeightGraph";
import { getPersonalizedTargetDate } from "src/utils/pace";
import {
  isFivePercentEligible,
  isImportantDateEligible,
} from "src/utils/userSegment/features";
import { formatUnit } from "../../utils/Unit";
import { getIndexFromGraphId } from "src/utils/pace/graphIndex";
import isEqual from "lodash/isEqual";

export const GRAPH_FONT = "12pt Brown LL";

export const PersonalizedPaceWeightGraph = memo(
  function PersonalizedPaceWeightGraph({
    questionId,
    startWeightKg,
    idealWeightKg,
    surveyAnswers,
    addFivePercentMarker,
    ariaLabel,
    ariaHidden = false,
    graphCustomization,
    showMedicationStart = false,
  }: {
    questionId?: string;
    startWeightKg: number;
    idealWeightKg: number;
    surveyAnswers: SurveyAnswersState;
    addFivePercentMarker?: boolean;
    ariaLabel?: string;
    ariaHidden?: boolean;
    graphCustomization?: GraphCustomization;
    showMedicationStart?: boolean;
  }) {
    const canvasRef = useRef<HTMLCanvasElement>();
    const graphRef = useRef<Chart>();

    const renderCanvas = useCallback(() => {
      const { weightDisplayUnit, targetWeightDisplay } =
        getWeightInformation(surveyAnswers);

      const targetDate = getPersonalizedTargetDate(
        questionId ? getIndexFromGraphId(questionId) : undefined,
        surveyAnswers
      );

      const displayTargetValue = formatUnit(
        targetWeightDisplay,
        weightDisplayUnit
      );

      // NOTE(claire): The chart uses a weight of Brown LL is not used elswhere in the flow,
      // thus it has not been loaded into the browser. This forces that specific file to be
      // loaded before we render the chart, preventing it from falling back to default fonts.
      // (Font size used here is irrelevant).
      document.fonts.load(GRAPH_FONT).then(() => {
        graphRef.current = drawPersonalizedPaceWeightGraph(
          startWeightKg,
          idealWeightKg,
          displayTargetValue,
          weightDisplayUnit,
          targetDate,
          graphRef.current,
          surveyAnswers,
          isImportantDateEligible(),
          addFivePercentMarker && isFivePercentEligible(),
          showMedicationStart,
          canvasRef.current,
          questionId,
          graphCustomization
        );
      });
    }, [
      startWeightKg,
      idealWeightKg,
      surveyAnswers,
      questionId,
      addFivePercentMarker,
      graphCustomization,
      showMedicationStart,
    ]);

    // Render once the element is in the DOM
    const handleCanvasRef = (ref: HTMLCanvasElement) => {
      canvasRef.current = ref;
      if (graphRef.current) {
        graphRef.current.destroy();
      }

      if (ref) {
        renderCanvas();
      }
    };

    // Rerender on prop updates
    useEffect(() => {
      if (!canvasRef.current) {
        return;
      }
      renderCanvas();
    }, [renderCanvas]);

    return (
      // Restrains the canvas height
      <div css={{ width: "100%" }}>
        <canvas
          ref={handleCanvasRef}
          css={{ minHeight: "250px" }}
          data-cy="personalized-weight-graph"
          aria-label={ariaLabel}
          aria-hidden={ariaHidden}
        />
      </div>
    );
  },
  (prevProps, currentProps) => isEqual(prevProps, currentProps)
);
