import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum OralsMedRolloutUserType {
  HOLDBACK = "HOLDBACK",
  VARIATION = "VARIATION",
  CONTROL = "CONTROL",
  FORCED_ENABLED = "FORCED_ENABLED",
  FORCED_DISABLED = "FORCED_DISABLED",
  PAID_TRAFFIC = "PAID_TRAFFIC",
}

export type PersistentBrowserFlagsState = {
  oralsMedRolloutUserType?: OralsMedRolloutUserType;
};

// Adding this state in order to preserve flags on device instead of preserving them just per session (browser session).
const initialState: PersistentBrowserFlagsState = {
  oralsMedRolloutUserType: undefined,
};

const persistentBrowserFlagsSlice = createSlice({
  name: "persistentBrowserFlags",
  initialState,
  reducers: {
    setOralsMedRolloutUserType(
      state,
      action: PayloadAction<OralsMedRolloutUserType>
    ) {
      return {
        ...state,
        oralsMedRolloutUserType: action.payload,
      };
    },
  },
});

export const { setOralsMedRolloutUserType } =
  persistentBrowserFlagsSlice.actions;

export default persistentBrowserFlagsSlice;
