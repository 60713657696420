import {
  BRANDED_PLAN_INELIGIBLE_HEALTH_RISKS,
  BrandedPlanIneligibleStates,
  COMPOUNDED_PLAN_INELIGIBLE_HEALTH_RISKS,
  CompoundedPlanIneligibleStates,
  MED_BMI_THRESHOLD_BRANDED_COMPOUNDED,
  MED_BMI_THRESHOLD_ORALS,
  ORALS_PLAN_INELIGIBLE_HEALTH_RISKS,
  OralsPlanIneligibleStates,
  INELIGIBLE_HEALTH_RISKS,
} from "src/components/refactored-survey/question-sets/insurance-survey-questions/utils/insuranceConstants";
import { isEligibleForOralsMedBuyflow } from "./userSegment/features";
import { getSubdivision } from "./meristemContext";
import zipState from "zip-state";
import { calculateBMIImperial, calculateBMIMetric } from "src/primitive/bmi";
import { HealthRiskOption } from "src/pages/survey/currentHealthRiskMedical";
import { getSurveyAnswers } from "src/hooks/survey/answers";

export enum MED_SKU {
  BRANDED = "BRANDED",
  COMPOUNDED = "COMPOUNDED",
  ORALS_METFORMIN = "ORALS_METFORMIN",
}

type EligibilityInfo = {
  bmiThreshold?: number;
  ineligibleHealthRisks: HealthRiskOption[];
  ineligibleStates?: Record<string, string>;
  extraEligibilityCheck?: () => boolean;
};

const ELIGIBILITY_INFO: Record<MED_SKU | "global", EligibilityInfo> = {
  [MED_SKU.BRANDED]: {
    bmiThreshold: MED_BMI_THRESHOLD_BRANDED_COMPOUNDED,
    ineligibleHealthRisks: BRANDED_PLAN_INELIGIBLE_HEALTH_RISKS,
    ineligibleStates: BrandedPlanIneligibleStates,
  },
  [MED_SKU.COMPOUNDED]: {
    bmiThreshold: MED_BMI_THRESHOLD_BRANDED_COMPOUNDED,
    ineligibleHealthRisks: COMPOUNDED_PLAN_INELIGIBLE_HEALTH_RISKS,
    ineligibleStates: CompoundedPlanIneligibleStates,
  },
  [MED_SKU.ORALS_METFORMIN]: {
    bmiThreshold: MED_BMI_THRESHOLD_ORALS,
    ineligibleHealthRisks: ORALS_PLAN_INELIGIBLE_HEALTH_RISKS,
    ineligibleStates: OralsPlanIneligibleStates,
    extraEligibilityCheck: () => {
      return isEligibleForOralsMedBuyflow();
    },
  },
  // These conditions apply to every plan
  global: {
    ineligibleHealthRisks: INELIGIBLE_HEALTH_RISKS,
  },
};

function getBMI() {
  const { heightFeet, heightInch, weight, heightCm, weightKg } =
    getSurveyAnswers();
  if (
    heightFeet !== undefined &&
    heightInch !== undefined &&
    weight !== undefined
  ) {
    return calculateBMIImperial(heightFeet, heightInch, weight);
  }
  if (heightCm !== undefined && weightKg !== undefined) {
    return calculateBMIMetric(heightCm, weightKg);
  }
  return NaN;
}

function getMedState() {
  const { zipcode } = getSurveyAnswers();
  // Note (Alex): Intially, we use the IP state as the source of truth for the state eligibility;
  // Once we collect the zipcode, we will only trust the state determined based on the zipcode
  let state = getSubdivision();
  if (zipcode !== undefined) {
    state = zipState(zipcode);
  }
  return state;
}

export function hasIneligibleHealthRisks(
  healthRisks: HealthRiskOption[],
  ineligibleHealthRisks: HealthRiskOption[]
) {
  const ineligibleAnswers = healthRisks.filter((answer) =>
    ineligibleHealthRisks.includes(answer)
  );

  return !!ineligibleAnswers.length;
}

export function getMedicalHealthRisks(): HealthRiskOption[] {
  const { currentHealthRiskMedical } = getSurveyAnswers();

  return (currentHealthRiskMedical || []).filter(
    (el) => typeof el === "string"
  ) as HealthRiskOption[];
}

export function getMedBMIThreshold() {
  let eligibilityKeys: Array<keyof typeof ELIGIBILITY_INFO> = ["global"];
  // Get the products that pass the extra check, this means we don't
  // take into account the orals bmi threshold if it's disabled
  eligibilityKeys = eligibilityKeys.concat(
    getEligibleMedProducts({
      checkBMI: false,
      checkHealthRisks: false,
      checkState: false,
    })
  );
  const bmiThresholds = eligibilityKeys
    .map((key) => {
      const { bmiThreshold } = ELIGIBILITY_INFO[key];
      return bmiThreshold;
    })
    .filter(Boolean);
  return Math.min(...bmiThresholds);
}

function meetsEligibilityInfo(
  eligibilityInfo: EligibilityInfo,
  {
    bmi,
    healthRisks,
    state,
  }: { bmi: number; healthRisks: HealthRiskOption[]; state: string },
  {
    checkBMI,
    checkHealthRisks,
    checkState,
  }: {
    checkBMI: boolean;
    checkHealthRisks: boolean;
    checkState: boolean;
  }
) {
  const {
    bmiThreshold,
    ineligibleHealthRisks,
    ineligibleStates,
    extraEligibilityCheck,
  } = eligibilityInfo;

  const passesBmiCheck = checkBMI && bmiThreshold ? bmi >= bmiThreshold : true;
  const passesHealthRiskCheck =
    checkHealthRisks && ineligibleHealthRisks
      ? !hasIneligibleHealthRisks(ineligibleHealthRisks, healthRisks)
      : true;
  const passesStateCheck =
    checkState && ineligibleStates ? !(state in ineligibleStates) : true;
  const passesExtraCheck = extraEligibilityCheck
    ? extraEligibilityCheck()
    : true;

  return (
    passesBmiCheck &&
    passesHealthRiskCheck &&
    passesStateCheck &&
    passesExtraCheck
  );
}

export function getEligibleMedProducts(
  options = {
    checkBMI: true,
    checkHealthRisks: true,
    checkState: true,
  }
): Array<MED_SKU> {
  const eligibleProducts = [];
  const bmi = getBMI();
  const healthRisks = getMedicalHealthRisks();
  const state = getMedState();

  const userInfo = {
    bmi,
    healthRisks,
    state,
  };

  if (!meetsEligibilityInfo(ELIGIBILITY_INFO.global, userInfo, options)) {
    return [];
  }

  Object.values(MED_SKU).forEach((sku) => {
    if (meetsEligibilityInfo(ELIGIBILITY_INFO[sku], userInfo, options)) {
      eligibleProducts.push(sku);
    }
  });
  return eligibleProducts;
}
